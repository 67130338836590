var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import axios from "axios";
import config from "config";
import { userStorage } from "services";
import { handleUnauthorizedError, redirectToLogin } from "../utils/ApiResponse";
import { LANGUAGE_CODE, getItem, getRefreshToken, removeAccessToken, removeRefreshToken, storeItem, updateLocalAccessToken, updateLocalRefreshToken, } from "../utils/LocalStorageUtil";
import { PERMISSION_DATA_KEY, TOKEN_KEY } from "../utils/StorageKeys";
var apiClient = axios.create({
    baseURL: config.api.host,
    timeout: 120000,
    headers: {
        Accept: "application/json",
    },
});
var getRefreshTokenAsync = function () { return __awaiter(void 0, void 0, void 0, function () {
    var rs, _a, token, refreshToken, _error_1;
    return __generator(this, function (_b) {
        switch (_b.label) {
            case 0:
                _b.trys.push([0, 3, , 4]);
                return [4 /*yield*/, apiClient.post("/auth/refreshtoken", {
                        refreshToken: getRefreshToken(),
                    })];
            case 1:
                rs = _b.sent();
                _a = rs.data, token = _a.token, refreshToken = _a.refreshToken;
                if (token) {
                    updateLocalAccessToken(token);
                }
                if (refreshToken) {
                    updateLocalRefreshToken(refreshToken);
                }
                return [4 /*yield*/, getMe()];
            case 2:
                _b.sent();
                return [3 /*break*/, 4];
            case 3:
                _error_1 = _b.sent();
                removeAccessToken();
                removeRefreshToken();
                redirectToLogin();
                return [2 /*return*/, Promise.reject(_error_1)];
            case 4: return [2 /*return*/];
        }
    });
}); };
var getMe = function () { return __awaiter(void 0, void 0, void 0, function () {
    var rs, affiliateStatus, displayName, email, avatar, user, data, _error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("/me")];
            case 1:
                rs = _a.sent();
                storeItem(PERMISSION_DATA_KEY, JSON.stringify(rs.data));
                affiliateStatus = rs.data.affiliateStatus;
                displayName = rs.data.displayName;
                email = rs.data.email;
                avatar = rs.data.avatar;
                user = userStorage.get();
                data = __assign(__assign({}, user), { affiliateStatus: affiliateStatus, displayName: displayName, email: email, avatar: avatar });
                userStorage.set(data);
                return [3 /*break*/, 3];
            case 2:
                _error_2 = _a.sent();
                return [2 /*return*/, Promise.reject(_error_2)];
            case 3: return [2 /*return*/];
        }
    });
}); };
apiClient.interceptors.request.use(function (request) { return __awaiter(void 0, void 0, void 0, function () {
    var token, language;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, getItem(TOKEN_KEY)];
            case 1:
                token = _a.sent();
                return [4 /*yield*/, getItem(LANGUAGE_CODE)];
            case 2:
                language = _a.sent();
                request.headers["locale"] = language ? language : "vn";
                if (!token) {
                    return [2 /*return*/, request];
                }
                if (request && request.headers) {
                    request.headers["Authorization"] = "Bearer " + token;
                }
                return [2 /*return*/, request];
        }
    });
}); }, function (error) { return Promise.reject(error); });
apiClient.interceptors.response.use(function (response) {
    return response;
}, function (error) { return __awaiter(void 0, void 0, void 0, function () {
    var originalConfig;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                originalConfig = error.config;
                if (originalConfig &&
                    originalConfig.url &&
                    originalConfig.url !== "/auth/signin" &&
                    error.response) {
                    if (error.response.status === 401 && !originalConfig._retry) {
                        originalConfig._retry = true;
                        getRefreshTokenAsync();
                        return [2 /*return*/, apiClient(originalConfig)];
                    }
                }
                return [4 /*yield*/, handleUnauthorizedError(error)];
            case 1:
                _a.sent();
                return [2 /*return*/, Promise.reject(error.response && error.response.data ? error.response.data : error)];
        }
    });
}); });
export { apiClient, getMe, getRefreshTokenAsync };
export default apiClient;
