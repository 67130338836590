export var STATUS_LOADING = "LOADING";
export var STATUS_SUCCEEDED = "SUCCEEDED";
export var STATUS_FAILED = "FAILED";
//Notification
export var NOTIFICATION_SUCCESS = "NOTIFICATION_SUCCESS";
export var NOTIFICATION_INFO = "NOTIFICATION_INFO";
export var NOTIFICATION_WARN = "NOTIFICATION_WARN";
export var NOTIFICATION_ERROR = "NOTIFICATION_ERROR";
export var NOTIFICATION_DEFAULT = "NOTIFICATION_DEFAULT";
export var NOTIFICATION_DARK = "NOTIFICATION_DARK";
export var NOTIFICATION_APPROVED_AFFILIATE = "APPROVED_AFFILIATE_TO_END_USER";
export var NOTIFICATION_REJECTED_AFFILIATE = "REJECTED_AFFILIATE_TO_END_USER";
export var TYPE_NAME_VALIDATION = "NAME";
export var TYPE_TEXT_LIMIT_VALIDATION = "TEXT_LIMIT";
export var TYPE_TEXT_VALIDATION = "TEXT";
export var TEXT_CHECK = "CHECK";
export var TYPE_NUMBER_VALIDATION = "NUMBER";
export var TYPE_ARRAY_VALIDATION = "ARRAY";
export var TYPE_URL_VALIDATION = "URL";
export var TYPE_EMAIL_VALIDATION = "EMAIL";
export var TYPE_TEXT_LIMIT_ONLY = "TEXT_LIMIT_ONLY";
export var TYPE_PASSWORD_VALIDATION = "PASSWORD";
export var TYPE_PHONE_VALIDATION = "PHONE";
export var TYPE_TEXT_PERCENTAGE_VALIDATION = "PERCENTAGE";
export var TYPE_TEXT_COMMISSION_VALIDATION = "COMMISSION";
export var VOUCHER_WIDTH_STANDARD = 461;
export var VOUCHER_HEIGHT_STANDARD = 198;
export var MEMBERSHIP_WIDTH_STANDARD = 461;
export var MEMBERSHIP_HEIGHT_STANDARD = 198;
export var SMALL_WIDTH_SCREEN = 640;
export var MEDIUM_WIDTH_SCREEN = 768;
export var ALLOWED_FILES = [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "image/png",
    "image/jpeg",
    "image/webp",
];
export var ALLOWED_IMAGE_FILES = ["image/png", "image/jpeg", "image/webp"];
export var MAX_FILES = 5;
export var oneMB = 1048576;
export var APP_NAME = "Multiple multipart large files (1GB And Beyond) uploads to the AWS S3 server using pre-signed URLs and react.js";
export var CHUNK_SIZE = 1024 * 1024 * 100; // 100MB, This must be bigger than or equal to 5MB, otherwise AWS will respond with: "Your proposed upload is smaller than the minimum allowed size"
export var TYPE_FREE_VOUCHER = "VOUCHER";
export var TYPE_FREE_FAN = "FAN";
export var TYPE_FREE_MEMBERSHIP = "MEMBERSHIP";
export var OVERVIEW_DATE_FORMAT = "YYYY-MM-DD";
