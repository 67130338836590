var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { apiClient } from "api";
import { handleResponse } from "utils/ApiResponse";
var orderUrl = "order";
export var getOrderDetailHistory = function (params) {
    return apiClient.get("admin/".concat(orderUrl), { params: params });
};
export var getOrder = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("".concat(orderUrl, "/view/"), {
                        params: params,
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_1 = _a.sent();
                return [2 /*return*/, Promise.reject(error_1)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getInvoicesByBrandAdmin = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("".concat(orderUrl, "/invoice/bybrandadmin"), {
                        params: params,
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_2 = _a.sent();
                return [2 /*return*/, Promise.reject(error_2)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var updateInvoiceInfoByBrandAdmin = function (id, body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.put("".concat(orderUrl, "/").concat(id, "/invoice/bybrandadmin"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_3 = _a.sent();
                return [2 /*return*/, Promise.reject(error_3)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getDetailInvoiceInfoByBrandAdmin = function (orderId, brandId) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("".concat(orderUrl, "/").concat(orderId, "/brand/").concat(brandId, "/invoice/bybrandadmin"))];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_4 = _a.sent();
                return [2 /*return*/, Promise.reject(error_4)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var updateInvoiceStatusByBrandAdmin = function (orderId, brandId, body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.put("".concat(orderUrl, "/").concat(orderId, "/brand/").concat(brandId, "/invoice/bybrandadmin"), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_5 = _a.sent();
                return [2 /*return*/, Promise.reject(error_5)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getUsageHistory = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("/usageHistory/me", { params: params })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_6 = _a.sent();
                return [2 /*return*/, Promise.reject(error_6)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getTrackingConversion = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("/affiliate/report/conversion/me", {
                        params: params,
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_7 = _a.sent();
                return [2 /*return*/, Promise.reject(error_7)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getExcelTrackingConversion = function (params, excel) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("/affiliate/report/conversion/me?excel=".concat(excel), {
                        params: params,
                        responseType: "arraybuffer",
                        headers: {
                            "Content-Disposition": 'attachment; filename="example.xlsx"',
                        },
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_8 = _a.sent();
                return [2 /*return*/, Promise.reject(error_8)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getOrderFree = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("".concat(orderUrl, "/free/"), {
                        params: params,
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_9 = _a.sent();
                return [2 /*return*/, Promise.reject(error_9)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getOrderDetail = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get(orderUrl, { params: params })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_10 = _a.sent();
                return [2 /*return*/, Promise.reject(error_10)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getOrderLast = function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("".concat(orderUrl, "/latest/"))];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_11 = _a.sent();
                return [2 /*return*/, Promise.reject(error_11)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getOrderListHistoryByAdmin = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_12;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("admin/".concat(orderUrl), { params: params })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_12 = _a.sent();
                return [2 /*return*/, Promise.reject(error_12)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getOrderDetailHistoryByAdmin = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_13;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("admin/orderDetail", { params: params })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_13 = _a.sent();
                return [2 /*return*/, Promise.reject(error_13)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getExcelOrderDetailFileMasterAdmin = function (params, excel, nfc) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_14;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("admin/orderDetail?excel=".concat(excel, "&isNfc=").concat(nfc), {
                        params: params,
                        responseType: "arraybuffer",
                        headers: {
                            "Content-Disposition": 'attachment; filename="example.xlsx"',
                        },
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_14 = _a.sent();
                return [2 /*return*/, Promise.reject(error_14)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getOrderDetailHistoryByBrand = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_15;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("".concat(orderUrl, "/bybrandadmin"), {
                        params: params,
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_15 = _a.sent();
                return [2 /*return*/, Promise.reject(error_15)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getQRCheckHistoryByBrand = function (params) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_16;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("usageHistory/by-brand", { params: params })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_16 = _a.sent();
                return [2 /*return*/, Promise.reject(error_16)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getTrackingConversionByBrandAdmin = function (params, role) { return __awaiter(void 0, void 0, void 0, function () {
    var apiUrl, response, error_17;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                apiUrl = role === "brandAdmin"
                    ? "/affiliate/report/conversion/by-brand"
                    : role === "admin"
                        ? "admin/affiliate/report/conversion"
                        : "";
                return [4 /*yield*/, apiClient.get(apiUrl, { params: params })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_17 = _a.sent();
                return [2 /*return*/, Promise.reject(error_17)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getExcelTrackingConversionFileByBrand = function (params, excel, role) { return __awaiter(void 0, void 0, void 0, function () {
    var apiUrl, response, error_18;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                apiUrl = role === "brandAdmin"
                    ? "/affiliate/report/conversion/by-brand?excel=".concat(excel)
                    : role === "admin"
                        ? "/admin/affiliate/report/conversion?excel=".concat(excel)
                        : "";
                return [4 /*yield*/, apiClient.get(apiUrl, {
                        params: params,
                        responseType: "arraybuffer",
                        headers: {
                            "Content-Disposition": 'attachment; filename="example.xlsx"',
                        },
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_18 = _a.sent();
                return [2 /*return*/, Promise.reject(error_18)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getPhysicalOrdersStaff = function (params, role) { return __awaiter(void 0, void 0, void 0, function () {
    var apiUrl, response, error_19;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                apiUrl = role === "brandAdmin"
                    ? "/order/physical/bybrandadmin"
                    : role === "admin"
                        ? "/admin/order/physical"
                        : "";
                return [4 /*yield*/, apiClient.get(apiUrl, { params: params })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_19 = _a.sent();
                return [2 /*return*/, Promise.reject(error_19)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getExcelOrderDetailFileBrandAdmin = function (params, excel, nfc) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_20;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("orderDetail/bybrandadmin?excel=".concat(excel, "&isNfc=").concat(nfc), {
                        params: params,
                        responseType: "arraybuffer",
                        headers: {
                            "Content-Disposition": 'attachment; filename="example.xlsx"',
                        },
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_20 = _a.sent();
                return [2 /*return*/, Promise.reject(error_20)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getExcelQRCheckHistoryFileByBrand = function (params, excel) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_21;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.get("usageHistory/by-brand?excel=".concat(excel), {
                        params: params,
                        responseType: "arraybuffer",
                        headers: {
                            "Content-Disposition": 'attachment; filename="example.xlsx"',
                        },
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_21 = _a.sent();
                return [2 /*return*/, Promise.reject(error_21)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getExcelPhysicalOrderFileByBrand = function (params, excel, role) { return __awaiter(void 0, void 0, void 0, function () {
    var apiUrl, response, error_22;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                apiUrl = role === "brandAdmin"
                    ? "/order/physical/bybrandadmin?excel=".concat(excel)
                    : role === "admin"
                        ? "/admin/order/physical?excel=".concat(excel)
                        : "";
                return [4 /*yield*/, apiClient.get(apiUrl, {
                        params: params,
                        responseType: "arraybuffer",
                        headers: {
                            "Content-Disposition": 'attachment; filename="example.xlsx"',
                        },
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_22 = _a.sent();
                return [2 /*return*/, Promise.reject(error_22)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var createOrder = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_23;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("".concat(orderUrl), body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_23 = _a.sent();
                return [2 /*return*/, Promise.reject(error_23)];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var placeOrder = function (body) { return __awaiter(void 0, void 0, void 0, function () {
    var response, error_24;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, apiClient.post("/placeOrder", body)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, handleResponse(response)];
            case 2:
                error_24 = _a.sent();
                return [2 /*return*/, Promise.reject(error_24)];
            case 3: return [2 /*return*/];
        }
    });
}); };
