import React from "react";
import { ToastContainer } from "react-toastify";
import "assets/styles/App.css";
import "assets/styles/common-styles.scss";
import "@splidejs/react-splide/css";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "react-quill/dist/quill.snow.css";
import "react-multi-carousel/lib/styles.css";
import { Provider } from "react-redux";
//Store Redux
import store from "./store";
import { ConfigProvider } from "antd";
import { QueryClientProvider } from "@tanstack/react-query";
import queryClient from "lib/query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import RouterRoot from "route/RouteRoot";
import AuthenticatorProvider from "components/AuthenticatiorProvider";
export default function App() {
    return (React.createElement(ConfigProvider, { theme: { token: { colorPrimary: "#F5784F" } } },
        React.createElement(QueryClientProvider, { client: queryClient },
            process.env.NODE_ENV === "development" && (React.createElement(ReactQueryDevtools, { initialIsOpen: false })),
            React.createElement(AuthenticatorProvider, null,
                React.createElement(Provider, { store: store },
                    React.createElement(RouterRoot, null),
                    React.createElement(ToastContainer, null))))));
}
