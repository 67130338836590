var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import Loading from "components/common/loadmore-btn/Loading";
import Loadable from "react-loadable";
var Login = Loadable({
    loader: function () { return import("pages/new-login/login"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var ForgotPassword = Loadable({
    loader: function () { return import("pages/new-login/forgotPassword/index"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var SignUp = Loadable({
    loader: function () { return import("pages/new-login/signup/index"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var ActiveUser = Loadable({
    loader: function () { return import("pages/new-login/activeUser/index"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var Home = Loadable({
    loader: function () { return import("pages/home/index"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var NotFoundPage = Loadable({
    loader: function () { return import("pages/notFoundPage/index"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var MembershipDetail = Loadable({
    loader: function () { return import("pages/membershipDetail/index"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var FanDetail = Loadable({
    loader: function () { return import("pages/fanDetail/index"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var SearchGlobal = Loadable({
    loader: function () { return import("pages/search-global/index"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var BrandDetail = Loadable({
    loader: function () { return import("pages/brands/brandDetail"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var Fans = Loadable({
    loader: function () { return import("pages/fans"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var Memberships = Loadable({
    loader: function () { return import("pages/memberships"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var Vouchers = Loadable({
    loader: function () { return import("pages/vouchers"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var Collections = Loadable({
    loader: function () { return import("pages/collections"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var CollectionDetail = Loadable({
    loader: function () { return import("pages/collections/collectionDetail"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var Brands = Loadable({
    loader: function () { return import("pages/brands/brands"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var AboutLoyalty = Loadable({
    loader: function () { return import("pages/about-loyalty"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var DetailScanQR = Loadable({
    loader: function () { return import("pages/detail-scan-qr"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var DigitalProduct = Loadable({
    loader: function () { return import("pages/digital-product"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var AboutOBranding = Loadable({
    loader: function () { return import("pages/about-obranding"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var AffiliateIntroduction = Loadable({
    loader: function () { return import("pages/affiliate-introdution"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var ObrandingCard = Loadable({
    loader: function () { return import("pages/obranding-card"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var ObrandingPartner = Loadable({
    loader: function () { return import("pages/obranding-partner"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var ObrandingAllPartners = Loadable({
    loader: function () { return import("pages/obranding-partner/ObrandingAllPartners"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var FAQPage = Loadable({
    loader: function () { return import("pages/about-loyalty/faq"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var TermPaymentPage = Loadable({
    loader: function () { return import("pages/about-loyalty/term-payment"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var TermUsePage = Loadable({
    loader: function () { return import("pages/about-loyalty/term-use"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var TermUseConditionPage = Loadable({
    loader: function () { return import("pages/about-loyalty/term-use-condition"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var TermSecurePage = Loadable({
    loader: function () { return import("pages/about-loyalty/term-secure"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var DisputeProcessPage = Loadable({
    loader: function () { return import("pages/about-loyalty/dispute-process"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var PaymentGuidePage = Loadable({
    loader: function () { return import("pages/about-loyalty/how-payment"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var PaymentLoyalty = Loadable({
    loader: function () { return import("pages/payment-loyalty"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var VoucherDetail = Loadable({
    loader: function () { return import("pages/voucherDetail/index"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var BrandAdmin = Loadable({
    loader: function () { return import("pages/brand-admin"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var MasterAdmin = Loadable({
    loader: function () { return import("pages/master-admin"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
// const AffiliatePayoutTransaction = Loadable(
//   {
//       loader: () => import('pages/master-admin/components/affiliatePayoutTransaction'),
//       loading: () => <Loading/>,
//       render(loaded, props: any) {
//           const Alerts = loaded.default;
//           return <Alerts {...props} />
//       }
//   }
// );
var AffiliatePage = Loadable({
    loader: function () { return import("pages/affiliate"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var AffiliateManagePage = Loadable({
    loader: function () { return import("pages/affiliate-manage"); },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var CartDetail = Loadable({
    loader: function () {
        return import("components/header-bar/parts/shopping-cart/shoppingCartDetail");
    },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var NFCInformation = Loadable({
    loader: function () {
        return import("pages/nfc-information");
    },
    loading: function () { return React.createElement(Loading, null); },
    render: function (loaded, props) {
        var Alerts = loaded.default;
        return React.createElement(Alerts, __assign({}, props));
    },
});
var routes = {
    signUp: {
        path: "/signup",
        element: React.createElement(SignUp, null),
    },
    activeUser: {
        path: "/verify-email",
        element: React.createElement(ActiveUser, null),
    },
    login: {
        path: "/login",
        element: React.createElement(Login, null),
    },
    forgotPassword: {
        path: "/forgot-password",
        element: React.createElement(ForgotPassword, null),
    },
    home: {
        path: "/",
        element: React.createElement(Home, null),
    },
    notFoundPage: {
        path: "*",
        element: React.createElement(NotFoundPage, null),
    },
    fan: {
        path: "/fan",
        element: React.createElement(Fans, null),
    },
    fanDetail: {
        path: "/fan/:fanId",
        element: React.createElement(FanDetail, null),
    },
    membership: {
        path: "/membership",
        element: React.createElement(Memberships, null),
    },
    membershipDetail: {
        path: "/membership/:membershipId",
        element: React.createElement(MembershipDetail, null),
    },
    vouchers: {
        path: "/voucher",
        element: React.createElement(Vouchers, null),
    },
    voucherDetails: {
        path: "/voucher/:voucherId",
        element: React.createElement(VoucherDetail, null),
    },
    searchResult: {
        path: "/search-result",
        element: React.createElement(SearchGlobal, null),
    },
    brands: {
        path: "/brand",
        element: React.createElement(Brands, null),
    },
    brandDetails: {
        path: "/brand/:brandId",
        element: React.createElement(BrandDetail, null),
    },
    collectionDetail: {
        path: "/collection/:collectionId",
        element: React.createElement(CollectionDetail, null),
    },
    collectionDetailWhiteList: {
        path: "/collection/:collectionId/whitelist",
        element: React.createElement(CollectionDetail, { isWhitelist: true }),
    },
    detailScanQR: {
        path: "/detail-scan-qr",
        element: React.createElement(DetailScanQR, null),
    },
    digitalProduct: {
        path: "/digital-product",
        element: React.createElement(DigitalProduct, null),
    },
    obrandingCard: {
        path: "/sanh-card",
        element: React.createElement(ObrandingCard, null),
    },
    aboutOBranding: {
        path: "/about-obranding",
        element: React.createElement(AboutOBranding, null),
    },
    affiliateIntroduction: {
        path: "/about-affiliate-program",
        element: React.createElement(AffiliateIntroduction, null),
    },
    obrandingPartner: {
        path: "/partners",
        element: React.createElement(ObrandingPartner, null),
    },
    obrandingAllPartners: {
        path: "/partners/all",
        element: React.createElement(ObrandingAllPartners, null),
    },
    aboutLoyalty: {
        path: "/about-loyalty",
        element: React.createElement(AboutLoyalty, null),
    },
    faq: {
        path: "/faq",
        element: React.createElement(FAQPage, null),
    },
    termPayment: {
        path: "/term-payment",
        element: React.createElement(TermPaymentPage, null),
    },
    termUesr: {
        path: "/term-use",
        element: React.createElement(TermUsePage, null),
    },
    termSecure: {
        path: "/term-secure",
        element: React.createElement(TermSecurePage, null),
    },
    disputeProcess: {
        path: "/dispute-process",
        element: React.createElement(DisputeProcessPage, null),
    },
    termUserCondition: {
        path: "/term-use-condition",
        element: React.createElement(TermUseConditionPage, null),
    },
    paymentGuide: {
        path: "/how-payment",
        element: React.createElement(PaymentGuidePage, null),
    },
    paymentRoyalty: {
        path: "/payment-loyalty",
        element: React.createElement(PaymentLoyalty, null),
    },
    cartDetail: {
        path: "/cart-detail",
        element: React.createElement(CartDetail, null),
    },
    affiliateRegistration: {
        path: "/register-affiliate",
        element: React.createElement(AffiliatePage, null),
    },
    brandAdminType: {
        path: "/manage/:brandType",
        element: React.createElement(BrandAdmin, null),
    },
    brandAdminTypeDetail: {
        path: "/manage/:brandType/:id",
        element: React.createElement(BrandAdmin, null),
    },
    brandAdminTypeDetailAction: {
        path: "/manage/:brandType/:id/:action",
        element: React.createElement(BrandAdmin, null),
    },
    brandAdminTypeAction: {
        path: "/manage/:brandType/:action",
        element: React.createElement(BrandAdmin, null),
    },
    myBrandAdminType: {
        path: "/my/:brandType",
        element: React.createElement(BrandAdmin, { isMe: "isMe" }),
    },
    myBrandAdminTypeDetailAction: {
        path: "/my/:brandType/:id/:action",
        element: React.createElement(BrandAdmin, { isMe: "isMe" }),
    },
    masterAdminType: {
        path: "/admin/:brandType",
        element: React.createElement(MasterAdmin, null),
    },
    masterAdminTypeDetailAction: {
        path: "/admin/:brandType/:id/:action",
        element: React.createElement(MasterAdmin, null),
    },
    masterAdminTypeAction: {
        path: "/admin/:brandType/:action",
        element: React.createElement(MasterAdmin, null),
    },
    masterAdminOrigin: {
        path: "/admin",
        element: React.createElement(MasterAdmin, null),
    },
    affiliateType: {
        path: "/manage-affiliate/:type",
        element: React.createElement(AffiliateManagePage, null),
    },
    affiliateTypeDetailAction: {
        path: "/manage-affiliate/:type/:id/:action",
        element: React.createElement(AffiliateManagePage, null),
    },
    affiliateTypeAction: {
        path: "/manage-affiliate/:type/:action",
        element: React.createElement(AffiliateManagePage, null),
    },
    affiliateOrigin: {
        path: "/manage-affiliate",
        element: React.createElement(AffiliateManagePage, null),
    },
    nfcInformation: {
        path: "/nfc",
        element: React.createElement(NFCInformation, null),
    },
};
export default routes;
