import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { checkUIPermission, isAuthenticated, getCurrentUser } from "utils/CommonUtil";
import { storeItem } from "utils/LocalStorageUtil";
import { LOGIN_REFERER } from "utils/StorageKeys";
var PrivateRoute = function (_a) {
    var children = _a.children;
    var isAuthen = isAuthenticated();
    if (!isAuthen) {
        var location_1 = useLocation();
        storeItem(LOGIN_REFERER, location_1 && location_1.pathname ? location_1.pathname : "");
    }
    var accessible = checkUIPermission(location.pathname, true);
    var permissionData = getCurrentUser();
    if (permissionData && permissionData.roleType) {
        var hasAccountantRole = false;
        var hasProductDepartmentRole = false;
        for (var _i = 0, _b = Object.keys(permissionData.roleType); _i < _b.length; _i++) {
            var roleType = _b[_i];
            for (var _c = 0, _d = permissionData.roleType[roleType]; _c < _d.length; _c++) {
                var role = _d[_c];
                if (role === "openlive_accountant") {
                    hasAccountantRole = true;
                }
                if (role === "production_department") {
                    hasProductDepartmentRole = true;
                }
            }
        }
        if (hasProductDepartmentRole && location.pathname.includes("admin") &&
            !location.pathname.includes("admin/physical-order")) {
            accessible = true;
            location.pathname = "/admin/physical-order";
        }
        if (hasAccountantRole && location.pathname.includes("admin") &&
            !location.pathname.includes("admin/affiliate-payouttransaction") &&
            !location.pathname.includes("admin/affiliate-incomestatement") &&
            !location.pathname.includes("admin/tracking-conversion") &&
            !location.pathname.includes("admin/orderhistory") &&
            !location.pathname.includes("admin/orderdetailhistory") &&
            !location.pathname.includes("admin/incomestatement") &&
            !location.pathname.includes("admin/payouttransaction")) {
            accessible = true;
            location.pathname = "/admin/affiliate-payouttransaction";
        }
    }
    return isAuthen ? (accessible ? (React.createElement(Outlet, null)) : (React.createElement(Navigate, { to: "/" }))) : (React.createElement(Navigate, { to: "/login" }));
};
export default PrivateRoute;
